.container{
  height: 100%;
  padding: 0 !important;
}
section{
.hero-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-bottom-left-radius: 80px;
  border-bottom-right-radius: 80px;
  box-shadow: 0 5px 10px rgb(0 0 0 / 0.2);
}
.logo {
  border-radius: 50%;
  object-fit: cover;
  max-width: 150px;
  display: block;
  margin: auto;
  position: relative;
  bottom: 70px;
  box-shadow: 0 5px 10px rgb(0 0 0 / 0.2);
 }
}




.back {
  width: 30px;
  padding: 15px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 30px;
  left: 30px;
  cursor: pointer;
}



.login {
  padding: 0 20px;
  margin: auto;
  h5{
    font-weight: 200;
  }
}
.global{
  max-width: 400px;
  margin: auto;

  
}
.PhoneInputInput{
  max-width: 350px;
  border-radius: 10px;
  background-color: #f5f5f5;
  border: none;
  font-size: 20px;
  padding: 20px 50px; 
}

.button {
  padding: 20px 100px;
  background-color: #f2ba06;
  border: none;
  border-radius: 10px;
  color: white;
  font-size: 20px;
  font-weight: bold;
  display: block;
  margin: 50px auto;
  cursor: pointer;
}
.button:hover {
  background-color: #f2bb06c1;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {.input{
  display: block;
  margin: auto;
}}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {.login{
  padding: 0 50px;;
}}

// /* Medium devices (landscape tablets, 768px and up) */
// @media only screen and (min-width: 768px) {...}

// /* Large devices (laptops/desktops, 992px and up) */
// @media only screen and (min-width: 992px) {...}

// /* Extra large devices (large laptops and desktops, 1200px and up) */
// @media only screen and (min-width: 1200px) {...}

.in{
  display: block;
  border: none;
  padding: 0;
  margin: auto;
  width: 6.0ch;
  background: repeating-linear-gradient(90deg, #f2ba06 0 , #f2ba06 1ch, transparent 0, transparent 1.5ch) 0 100%/ 10ch 2px no-repeat;
  font: 5ch droid sans mono, consolas, monospace;
  letter-spacing: 0.5ch;
}
.in:focus{
  outline: none;
  color: #f2ba06;
}
