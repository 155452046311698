.yellow {
  background-color: #f2ba06;
  height: 100%;
  padding: 20px 20px;
}

.header {
  text-align: center;
  color: white;
  padding-top: 80px;
  padding-bottom: 10px;
}
.container {
  padding: 20px;
}
.outer {
  max-width: 700px;
  height: 10px;
  border-radius: 30px;
  margin:20px auto;
  background-color: #f5f5f5;
}
.inner {
  max-width: 30%;
  background-color: #dd9610;
  height: 10px;
  border-radius: 30px;
}
.inner2 {
  max-width: 65%;
  background-color: #dd9610;
  height: 10px;
  border-radius: 30px;
}
.inner3 {
  max-width: 100%;
  background-color: #dd9610;
  height: 10px;
  border-radius: 30px;
}

.white {
  max-width: 700px;
  height: 700px;
  background-color: white;
  border-radius: 50px;
  margin: auto;
}
@media only screen and (max-width: 720px) {
  .white {
    height: 800px;
  }
}
.images {
  width: 300px;
  height: 300px;
}
.image_class {
  display: flex;
  padding: 25px;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 0px;
}

.buttons {
  padding: 20px;
  background-color: #f2ba06;
  border: none;
  width: 300px;
  border-radius: 10px;
  color: white;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
}

.wrapper2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 100px;
  margin-top: 80px;
}
.wrapper3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-items: center;
  margin-top: 50px;
}
@media only screen and (max-width: 720px) {
  .wrapper3 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    margin-top: 20px;
  }
  
}

.button1 {
  border: none;
  background-color: white;
  width: 200px;
  height: 200px;
  border-radius: 10px;
  font-size: 15px;
  cursor: pointer;
  box-shadow: 0 5px 10px rgb(0 0 0 / 0.2);
}
.im1 {
  width: 60%;
  position: relative;
  bottom: 90px;
}

.im2 {
  width: 250px;
  position: relative;
  bottom: 50px;
  left: -30px;
}
.al {
  position: relative;
  top: -80px;
  font-size: 20px;
}

.al2 {
  position: relative;
  top: -50px;
  font-size: 20px;
}

.how {
  display: block;
  margin: auto;
  width: 95%;
  height: 40%;
  object-fit: cover;
  border-radius: 30px;
}

.button3 {
  border: none;
  background-color: white;
  width: 130px;
  height: 130px;
  border-radius: 30px;
  font-size: 15px;
  cursor: pointer;
  box-shadow: 0 5px 10px rgb(0 0 0 / 0.2);
  margin: 5px;
}
.imim {
  width: 80px;
}
