html,
body {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}
h1,h2,h3,h4,h5,h6,p{
  margin: 0px ;
}
p{
  padding: 40px 0px; 
}
h1 
  { 
    font-size: 2.2em;
   }     
h2 
    { 
    font-size: 2.0em;     
} 
     
h3 
    { 
    font-size: 1.8em; 
} 
     
h4 
    { 
    font-size: 1.6em;     
    
} 
     
h5 
    { 
    font-size: 1.4em;     
} 
     
p 
    { 
    font-size: 1.2em;     
}